const loginData = (userDetails) => {
  localStorage.setItem("token", userDetails.token);
  localStorage.setItem("userType", userDetails.data.UserType);
  localStorage.setItem("userId", userDetails.data.ID);
  localStorage.setItem("hospitalId", userDetails.data.HospitalID);
  localStorage.setItem("userLogin", userDetails.data.FirstName + " " + userDetails.data.LastName);
  localStorage.setItem("userPicture", userDetails.data.Picture);
};

export default loginData;

export const auth_user = () => {
  const user = {
    token: localStorage.getItem("token"),
    userType: localStorage.getItem("userType"),
    userId: localStorage.getItem("userId"),
    hospitalId: localStorage.getItem("hospitalId"),
    userLogin:  localStorage.getItem("userLogin"),
    userPicture:  localStorage.getItem("userPicture"),
  };
  return user;
};

export const isLogin = () => {
  if (localStorage.getItem("token")) return true;
  return false;
};

export const logout = () => localStorage.clear();
