/* const Colors = {
  primary: "#1285EF",
  active: "#4CAF50",
  secondary: "#E2E9EA",
  default: "#A2A2A2",
  error: "#F4586C",
  warning: "#F9A000",
  info: "#22C0FA",
  black: "#000",
  white: "#fff",
  green: "#62C36D",
  grey: "#DDDDDD",
  appBodyColor: "#f0f2f5",
  sentChipColor: "#62C36D",
};
 */
const Colors = {
  primary: "#18A457",
  active: "#4CAF50",
  secondary: "#E2E9EA",
  default: "#A2A2A2",
  error: "#F4586C",
  warning: "#F9A000",
  info: "#22C0FA",
  black: "#000",
  white: "#fff",
  green: "#62C36D",
  grey: "#DDDDDD",
  appBodyColor: "#f0f2f5",
  sentChipColor: "#62C36D",
};

export default Colors;
