import { Auth } from "../../actionTypes";

const initialState = {
  user: {},
  pswd: {},
  isLoading: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case Auth.LOGIN_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case Auth.LOGIN_SUCCESS:
      return {
        ...state,
        user: { success: true, ...action.loginAction },
      };
    case Auth.LOGIN_FAILURE:
      return {
        ...state,
        user: { success: false, ...action.error },
      };

    case Auth.LIST_USER_SUCCESS:
      return {
        ...state,
        userList: { success: true, ...action.data },
      };
    case Auth.LIST_USER_FAILURE:
      return {
        ...state,
        userList: { success: false, ...action.error },
      };

    default:
      return state;
  }
};
export default AuthReducer;
