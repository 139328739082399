import { Password } from "../../actionTypes";

const initialState = {
    forgotPassword: {},
    setPassword: {},
};

const SetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case Password.FORGOTPASSWORD:
            const forgotPassword = action.data ? { forgotPassword: { ...action.data } } : null;
            return {
                ...state,
                ...forgotPassword,
                isLoading: action.isLoading,
            };
        case Password.SET_PASSWORD:
            const setPassword = action.data ? { setPassword: { ...action.data } } : null;
            return {
                ...state,
                ...setPassword,
                isLoading: action.isLoading,
            };
        default:
        return state;
    }
};
export default SetPasswordReducer;
