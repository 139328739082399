import { Chart } from "../../actionTypes";

const initialState = {
  chartData: {},
  chartList: {},
  contextList: {},
};

const ChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case Chart.CREATE_CHART_SUCCESS:
      const chartCreateData = action.data ? { chartData: { ...action.data } } : null;
      return {
        ...state,
        ...chartCreateData,
        isLoading: action.isLoading,
      };

    case Chart.CHART_CREATION_DETAILS:
      const chartCreationDetails = action.data ? { createChartDetails: { ...action.data } } : null;

      return {
        ...state,
        ...chartCreationDetails,
        isLoading: action.isLoading,
      };

    //CHART DASHBOARD
    case Chart.CHART_LIST:
      const isData = action.data ? { chartList: { ...action.data } } : null;
      return {
        ...state,
        ...isData,
        isLoading: action.isLoading,
      };

    case Chart.CHART_UPDATE_STATUS:
      let updateStatus = null;
      if (action?.typeOfChart === "") {
        const updatedData = action.data ? state.chartList?.rows?.map((x) => (x.ID === action.data?.ID ? { ...x, Status: action.data?.Status } : x)) : null;
        updateStatus = updatedData ? { chartList: { ...state.chartList, rows: updatedData } } : null;
      } else {
        const updatedData = action.data ? state.chartList?.rows?.filter((x) => x.ID !== action.data?.ID) : null;
        updateStatus = updatedData ? { chartList: { ...state.chartList, rows: updatedData } } : null;
      }
      return {
        ...state,
        ...updateStatus,
        isLoading: action.isLoading,
      };

    case Chart.CREATE_CONTEXT:
      const createContext = action.data ? { contextList: { ...state.contextList, rows: [...state.contextList?.rows, action.data] } } : null;
      return {
        ...state,
        ...createContext,
        isContextLoading: action.isLoading,
      };
    case Chart.CONTEXT_LIST:
      const isContextData = action.data ? { contextList: { ...action.data } } : null;
      return {
        ...state,
        ...isContextData,
        isContextLoading: action.isLoading,
      };
    case Chart.CONTEXT_LIST_DELETE:
      const deleteData = action.data ? state.contextList?.rows?.filter((item) => item.ID !== action.data?.ID) : null;
      const contextDeleteList = deleteData ? { contextList: { ...state.contextList, rows: deleteData } } : null;

      return {
        ...state,
        ...contextDeleteList,
        isContextLoading: action.isLoading,
      };

    default:
      return state;
  }
};
export default ChartReducer;
