import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Header from "../Header";
import Footer from "../Footer";
import SideBar from "../SideBar";

const AppLayout = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      height: "100%",
      width: "100%",
      overflow: "hidden !important",
    },
    toolbar: theme.mixins.toolbar,
    content: {
      height: "calc(100vh - 80px)",
      height: "calc(-webkit-fill-available - 80px)",
      backgroundColor: theme.palette.background.default,
      // backgroundColor: "#fff",
      // padding: theme.spacing(3),
      marginTop: props?.noSideBar ? 0 : 80,
      overflow: "auto",
      width: "100%",
      padding: 0,
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <Grid className={classes.root}>
      {props?.noSideBar ? null : <Header {...{ open, setOpen }}/>}
      {props?.noSideBar ? null : <SideBar {...{ open, setOpen }} />}
      <Grid className={classes.content}>{props.children}
      <Footer /> 
      </Grid>
    </Grid>
    
  );
};

export default AppLayout;
