import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Box, List, ListItem } from "@material-ui/core";
import { footerLinks } from "../../../Constants/footer";

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    textAlign: "center",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    /*  marginTop: theme.spacing.unit * 8,
    padding: `${theme.spacing.unit * 6}px 0`, */
  },
  copyRights: {
    fontSize: "15px",
    fontWeight: 600,
  },
});

function Footer(props) {
  const { classes, variant } = props;

  return (
    <footer className={classes.footer}>
      <Paper className={classes.root} elevation={1} sx={{ display: "flex" }}>
        <Typography component="p" className={classes.copyRights}>
          Copyright © 2021 - {new Date().getFullYear()} Growthscape, LLC. All
          rights reserved.
        </Typography>
        {variant === "login" && (
          <>
            <Box>
              {footerLinks.map((data) => (
                <a href={data.url} style={{ marginRight: 10 }} target="_blank">
                  {data.name}
                </a>
              ))}
            </Box>
            {/* <br></br> */}
            <Box style={{ marginTop: 6 }}>
              For any questions please contact{" "}
              <a href="mailto:frontdesk@residentgrowthchart.com">
                frontdesk@residentgrowthchart.com
              </a>
            </Box>
          </>
        )}
      </Paper>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);