import React, { Suspense } from "react";
import "./App.css";
import { Switch, Redirect } from "react-router-dom";
import { useLocation } from "react-router";
import PrivateRoute from "./route/Private";
import PublicRoute from "./route/Public";
import AppLayout from "./Components/molecules/AppLayout";
import {
  PrivateRouteArray,
  PublicRouteArray,
  ManagerRouteArray,
  FacultyRouteArray,
} from "./RouteArray";
import { connect } from "react-redux";
import { auth_user } from "./Config/util";
import Snackbar from "./Components/molecules/SnackBar";
import CenterCircularLoader from "./Components/atoms/CenterCircularLoader";

function App(props) {
  const userId = auth_user()?.userId || props.user?.userId;
  const userType = auth_user()?.userType || props.user?.userType;
  let { pathname } = useLocation();
  let routerArrayList;
  return (
    <>
      <Suspense fallback={<CenterCircularLoader disablePaper />}>
        <Switch>
          {PublicRouteArray.map((route, i) => (
            <PublicRoute
              isAuthenticated={userId ? true : false}
              path={route.path}
              exact={route.exact}
              component={route.component}
              key={i}
            />
          ))}
          {!userId ? <Redirect to="/" /> : null}
          {userType >= "1" && userType <= "4" ? (
            <AppLayout
              noSideBar={
                pathname == "/charts/createchart" ||
                pathname == "/evaluations" 
              //  || pathname == "/cccevaluationlist"
              }
            >
              {userType === "1"
                ? FacultyRouteArray.map((route, i) => {
                    // console.log(route.path, route.allowedUsersType.includes(userType))
                    return (
                      <PrivateRoute
                        isAuthenticated={true || false}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                        key={i}
                      />
                    );
                  })
                : userType === "2"
                ? FacultyRouteArray.map((route, i) => {
                    // console.log(route.path, route.allowedUsersType.includes(userType))
                    return (
                      <PrivateRoute
                        isAuthenticated={true || false}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                        key={i}
                      />
                    );
                  })
                : userType === "3"
                ? ManagerRouteArray.map((route, i) => {
                    // console.log(route.path, route.allowedUsersType.includes(userType))
                    return (
                      <PrivateRoute
                        isAuthenticated={true || false}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                        key={i}
                      />
                    );
                  })
                : userType === "4"
                ? ManagerRouteArray.map((route, i) => {
                    // console.log(route.path, route.allowedUsersType.includes(userType))
                    return (
                      <PrivateRoute
                        isAuthenticated={true || false}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                        key={i}
                      />
                    );
                  })
                : null}
            </AppLayout>
          ) : null}
        </Switch>
      </Suspense>
      <Snackbar
        {...props.snackProps}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
    snackProps: state.global.snackBarProps,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     // increment: () => dispatch(setRoute()),
//   };
// };
export default connect(mapStateToProps)(App);
