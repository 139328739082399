import axios from "axios";
import { API_ROUTE } from "../Constants/gateway";
import { auth_user } from "./util";

const gateWay = axios.create({
  baseURL: API_ROUTE,
  timeout: 20000,
});

gateWay.interceptors.request.use(function (config) {
  config.headers = { ...config.headers, Authorization: `Bearer ${auth_user().token}` };
  return config;
});
gateWay.interceptors.response.use(
  (res) => {
    return res;
  },
  function (res) {
    if (res.response && res.response.status == 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return res;
  }
);

export default gateWay;

//only for login- passing api without authorization token
export const authenticator = axios.create({
  baseURL: API_ROUTE,
  timeout: 20000,
});
