import { Auth } from "../../actionTypes";
import loginData from "../../../Config/util";
import gateWay, { authenticator } from "../../../Config/authorizationService";
import { userEndpoints } from "../../../Config/serviceEndPoints";
import { setSnackBarNotifications } from "../Global";

export const login = (param) => async (dispatch) => {
  let snackbarOptions = { open: true };
  await dispatch({ type: Auth.LOGIN_LOADING, isLoading: true });
  try {
    const res = await authenticator.post(userEndpoints.login, param);
    const userDetails = res.data;
    if (userDetails.success) {
      snackbarOptions = { ...snackbarOptions, variant: "success", message: userDetails?.message };
      loginData(userDetails);
      await dispatch({ type: Auth.LOGIN_SUCCESS, loginAction: userDetails.data });
    } else {
      snackbarOptions = { ...snackbarOptions, variant: "error", message: userDetails?.message };
      await dispatch({ type: Auth.LOGIN_FAILURE, error: userDetails });
    }
    await dispatch({ type: Auth.LOGIN_LOADING, isLoading: false });
    dispatch(setSnackBarNotifications({ ...snackbarOptions }));
    return Promise.resolve({ success: res?.data?.success || false, loginAction: userDetails.data  })
  } catch (e) {
    await dispatch(setSnackBarNotifications({ open: true, variant: "error", message: "Something went wrong!" }));
    await dispatch({ type: Auth.LOGIN_FAILURE, error: e.response && e.response.data });
  }
};

export const logout = (param) => async (dispatch) => {
  await dispatch({ type: Auth.LOGIN_SUCCESS, loginAction: { success: false } });
};

export const getUser = (params) => async (dispatch) => {
  try {
    const res = await gateWay.get(userEndpoints.listUser, { params });
    if (res.status === 200) {
      const userDetails = res.data;
      if (userDetails.success) {
        await dispatch({ type: Auth.LIST_USER_SUCCESS, data: userDetails.data });
      } else {
        await dispatch({ type: Auth.LIST_USER_FAILURE, error: userDetails });
      }
    } else {
      await dispatch({ type: Auth.LIST_USER_FAILURE, error: res.data });
    }
  } catch (e) {
    await dispatch({ type: Auth.LIST_USER_FAILURE, error: e.response && e.response.data });
  }
};
