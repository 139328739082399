import { Member } from "../../actionTypes";

const initialState = {
  membersList: {},
};

const MemberReducer = (state = initialState, action) => {
    switch (action.type) {
        case Member.MEMBER_LIST:  
            const isData = action.data ? { membersList: { ...action.data } } : null;
            return {
                ...state,
                ...isData,
                isLoading: action.isLoading,
            };
        case Member.ADD_MEMBER:  
            const addMember = action.data ? { addMember: { ...action.data } } : null;
            return {
                ...state,
                ...addMember,
                isLoading: action.isLoading,
            };
        case Member.UPLOAD_IMAGE:  
            const imageDetails = action.data ? { imageDetails: { ...action.data } } : null;
            return {
                ...state,
                ...imageDetails,
                isLoading: action.isLoading,
            };
        case Member.USER_TYPES:  
            const userTypes = action.data ? { userTypes: { ...action.data } } : null;
            return {
                ...state,
                ...userTypes,
                isLoading: action.isLoading,
            };
        case Member.ARCHIVE_USER:  
            let updateStatus = null;
            if (action?.typeOfMember === 0) {
            const updatedData = action.data ? state.membersList?.data?.rows?.map((x) => (x.ID === action.data?.ID ? { ...x, Archive: action.data?.Archive } : x)) : null;
            updateStatus = updatedData ? { membersList: { ...state.membersList, data: {rows: updatedData, count : state.membersList?.data?.count } } } : null;
            } else {
            const updatedData = action.data ? state.membersList?.data?.rows?.filter((x) => x.ID !== action.data?.ID) : null;
            updateStatus = updatedData ? { membersList: { ...state.membersList, data: {rows: updatedData, count : state.membersList?.data?.count } } } : null;
            }
            console.log("data", action.data);
            const archieveUser = action.data ? { archieveUser: { ...action.data } } : null;
            return {
                ...state,
                ...updateStatus,
                isLoading: action.isLoading,
            };
        case Member.DELETE_USER:  
            console.log("action", action.data)
            // const deletedUser = action.data ? { deletedUser: { ...action.data } } : null;
            const deleteData = action.data ? state.membersList?.data?.rows?.filter((item) => item.ID !== action.data?.data) : null;
            const memberDeleteList = deleteData ? { membersList: { ...state.membersList, data: { rows: deleteData, count: state.membersList?.data?.count - 1}  } } : null;
            console.log("memberDeleteList", memberDeleteList)
            return {
                ...state,
                ...memberDeleteList,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
};
export default MemberReducer;
