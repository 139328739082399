import React from "react";

const Login = React.lazy(() => import("./Components/pages/Auth"));
const Chart = React.lazy(() => import("./Components/pages/Chart"));
const CreateChart = React.lazy(() => import("./Components/pages/Chart/Createchart"));

const Members = React.lazy(() => import("./Components/pages/Members"));
const SetPassword = React.lazy(() => import("./Components/pages/SetPassword"));
const ForgotPassword = React.lazy(() => import("./Components/pages/ForgotPassword"));
const Evaluations = React.lazy(() => import("./Components/pages/CCCEvaluations"));
const GrowthChart = React.lazy(() => import("./Components/pages/GrowthChart"));
const CCCEvaluations = React.lazy(() => import("./Components/pages/CCCEvaluationsOld"));
const MyProgram =React.lazy(()=>import("./Components/pages/Myprogram"));
const Directory =React.lazy(()=>import("./Components/pages/Directory"));
const Home = React.lazy(()=>import("./Components/pages/Home"));

export const ManagerRouteArray = [
  { path: "/charts", component: Chart, exact: true },
  { path: "/charts/createchart", component: CreateChart, exact: true },
  { path: "/members", component: Members, exact: true },
  { path: "/evaluations", component: GrowthChart, exact: true },
  { path: "/cccevaluationlist", component: Evaluations, exact: true },
  // {path:"/myprogram" , component: MyProgram , exact: true},
  // {path:"/directory" , component: Directory , exact: true},
  // {path:"/home", component:Home ,exact:true},
];

export const FacultyRouteArray = [
  { path: "/evaluations", component: GrowthChart, exact: true },
  /* { path: "/charts/createchart", component: CreateChart, exact: true },
  { path: "/members", component: Members, exact: true }, */
];

export const PrivateRouteArray = [
  { path: "/charts", component: Chart, exact: true },
  { path: "/charts/createchart", component: CreateChart, exact: true },
  { path: "/members", component: Members, exact: true },
];

export const PublicRouteArray = [
  // { path: "/apploading", component: Dashboard, exact: true },
  { path: "/", component: Login, exact: true },
  { path: "/set-password/:token/:actionType", component: SetPassword },
  { path: "/forgot-password/", component: ForgotPassword },
  // { path: "/reset-password", component: Login },
];
