import React from "react";

function NotificationIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="21"
            fill="none"
            viewBox="0 0 18 21"
        >
            <path
                fill="#000"
                d="M7.146 2.248a2 2 0 013.708 0A7.003 7.003 0 0116 9v4.697l1.832 2.748A1 1 0 0117 18h-4.535a3.501 3.501 0 01-6.93 0H1a1 1 0 01-.832-1.555L2 13.697V9c0-3.224 2.18-5.94 5.146-6.752zM7.586 18a1.5 1.5 0 002.829 0h-2.83.001zM9 4a5 5 0 00-5 5v5a1 1 0 01-.168.555L2.869 16H15.13l-.963-1.445A.999.999 0 0114 14V9a5 5 0 00-5-5z"
            ></path>
        </svg>
    );
}

export default NotificationIcon;
