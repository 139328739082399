import { CCCEvaluations } from "../../actionTypes";

const initialState = {
    evaluationsList: {},
    unevaluatedList: null,
    evaluatedList: null,
    publishedList: []
};

const MemberReducer = (state = initialState, action) => {
    switch (action.type) {
        case CCCEvaluations.EVALUATIONS_LIST:
            const isData = action.data ? { evaluationsList: { ...action.data } } : null;
            return {
                ...state,
                ...isData,
                isLoading: action.isLoading,
            };
        case CCCEvaluations.UNEVALUATED_LIST_SUCCESS:
            return {
                ...state,
                unevaluatedList: action.data.data
            }
        case CCCEvaluations.SAVE_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbackSuccess: action.data.success
            }
        case CCCEvaluations.SAVE_FEEDBACK_FAILURE:
            return {
                ...state,
                feedbackSuccess: action.data.success
            }
        case CCCEvaluations.EVALUATED_LIST_SUCCESS:
            return {
                ...state,
                evaluatedList: action.data
            }
        case CCCEvaluations.START_CCC_LIST:
            return {
                ...state,
                residentList: action.data
            }
        case CCCEvaluations.PUBLISHED_LIST_REQUEST:
            return {
                ...state,
                publishedList:[],
                isLoading: action.isLoading,
            }
        case CCCEvaluations.PUBLISHED_LIST_SUCCESS:
            return {
                ...state,
                publishedList: action.data,
                isLoading: action.isLoading,
            }
        case CCCEvaluations.PUBLISHED_LIST_FAILURE:
            return {
                ...state,
                publishedList: action.data,
                isLoading: action.isLoading,
            }
        default:
            return state;
    }
};
export default MemberReducer;
