import { createTheme } from "@material-ui/core/styles";

import Colors from "./Constants/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.secondary,
      contrastText: Colors.black,
    },
    error: {
      main: Colors.error,
      contrastText: Colors.white,
    },
    warning: {
      main: Colors.warning,
      contrastText: Colors.white,
    },

    white: {
      main: Colors.white,
    },
    black: {
      main: Colors.black,
    },
    light: {
      main: Colors.light,
    },
    dark: {
      main: Colors.dark,
    },

    background: {
      default: Colors.appBodyColor,
    },
    text: { primary: "#1E1E2D", secondary: "#707070" },
  },
  typography: {
    fontFamily: ["SF Pro Display Regular"].join(","),
    fontStyle: "normal",
    button: {
      fontSize: "1rem",
      fontFamily: "SF Pro Display Regular",
      fontWeight: 600,
      textTransform: "none",
    },

    subtitle1: {
      fontSize: "1rem",
      fontWeight: 800,
    },

    subtitle2: {
      fontSize: "1rem",
      fontWeight: 400,
      fontFamily: "SF Pro Text Regular",
    },

    body1: {
      fontSize: "1.3rem",
      fontWeight: 600,
      fontFamily: "SF Pro Text Regular",
    },

    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      fontFamily: "SF Pro Text Regular",
    },

    caption: {
      fontSize: ".75rem",
      // fontWeight: 800
    },

    h1: {
      fontWeight: 900,
    },

    h2: {
      fontSize: "2.437rem",
      fontWeight: 700,
    },

    h3: {
      fontSize: "2.125rem",
      fontWeight: 500,
    },

    h4: {
      fontWeight: 900,
    },

    h5: {
      fontWeight: 900,
      color: "#1F1F1F",
    },

    h6: {
      // fontSize: "14px",
      fontWeight: 700,
    },
  },
  overrides: {
    MuiButton: {
      sizeSmall: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 90,
        borderRadius: 10,
      },
      sizeLarge: {
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: "2rem",
        paddingRight: "2rem",
        minWidth: 200,
        borderRadius: 10,
      },
      contained: {
        padding: ".625rem 2rem",
        boxShadow: "none",
        fontSize: "1rem",
        borderRadius: 10,
        minWidth: 130,
      },
      outlined: {
        padding: ".625rem 2rem",
        boxShadow: "none",
        fontSize: "1rem",
        minWidth: 130,
        borderRadius: 10,
      },
    },
    MuiInputBase: {
      input: {
        fontWeight: 400,
        fontFamily: 'SF Pro Display Regular',
      },
      root: {
        minWidth: 130,
      },
    },

    MuiMenuItem: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiChip: {
      root: {
        minWidth: 80,
        // color: "#fff",
        // backgroundColor: Colors.very_light_grey,
      },
    },
  },
});

export default theme;
