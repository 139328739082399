import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
// import { headerData } from "./helper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./header.css";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useLocation } from "react-router-dom";
import NotificationIcon from "../../../Assets/Svg/notificationIcon"
import RGCLogo from '../../../Assets/Images/DashLogo.png'
// import CloseIcon from "@material-ui/icons/Close";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import { setRoute } from "../../../store/actions/Global";
import SimpleListMenu from "../Menu";
import { getUser, logout } from "../../../store/actions/Auth";
import DialogNotification from "../Modal";

// import AutoCompleteBox from "../Autocomplete";
// import SideDrawer from "../SideDrawer";

const useStyles = makeStyles(() => ({
  appBar: {
    width: "100%",
    zIndex: 10,
  },
  // selected: {
  //   backgroundColor: `${Colors.White} !important`,
  //   color: Colors.white,
  // },
  // buttonHover: {
  //   backgroundColor: Colors.White,
  // },
  listRoot: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
   /*  marginLeft: "2%", */
    cursor: "pointer",
  },
  regular: {
    height: 80,
  },
}));
// const modalWrapper = {
//   position: "absolute",
//   width: "100%",
//   height: "100%",
//   background: "#fff",
//   left: 0,
//   bottom: 0,
//   overflow: "auto",
//   zIndex: 1,
//   opacity: 1,
// };

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(false);

  const { pathname } = location;
  const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);


  // const handleRouteChange = (route) => {
  //   props.setRoute(route);
  //   history.push(route);
  // };

  const classes = useStyles();
  const matches = useMediaQuery("(max-width:767px)");
  const { setOpen } = props;
  useEffect(() => {
    if (!matches && sideDrawerOpen) {
      setSideDrawerOpen(false);
    }
  }, [matches, sideDrawerOpen]);

  const componentDidMount = async () => {
    let userId = localStorage.getItem("userId");
    await props.getUser({ ID: userId });
  };
  useEffect(() => {
    componentDidMount();
  }, []);

  const handleMenuItemClick = (options) => {
    if (options === "logout") {
      setLogoutModalOpen(true)
      // props.logout();
      // history.push("/");
      // localStorage.clear();
    }
  };


  const onRenderComponent = () => {
    return (
      <Grid style={{ minHeight: 80, padding: 20, display: "flex", alignItems: "center" }}>
        <Typography variant="body2" color="textSecondary">
          Are you sure you want to logout?
        </Typography>
      </Grid>
    );
  };

  const handleDialogWindowClose = () => {
    setLogoutModalOpen(false);
  };

  const onDialogActionsOnClick = async () => {
    await props.logout();
    localStorage.clear();
    history.push("/");
  };
  return (
    <>
      <CssBaseline />
      <AppBar color="inherit" position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.regular}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0}>
            <img className={classes.logo}  src={RGCLogo} />
            {/* <Typography variant="h5" style={{ fontWeight: "bold", fontSize: 22 }}>Growthscape</Typography> */}
            {/* <Grid style={{ display: matches ? "none" : "flex", flex: 0.8, maxWidth: 600, justifyContent: "space-around", alignItems: "center" }}>
              
              <Typography variant="subtitle2" style={{ fontWeight: pathname === "/members" ? "bold" : "normal", cursor: "pointer" }} onClick={() => handleRouteChange("/members")}>
                Members
              </Typography>
              <Typography variant="subtitle2" style={{ fontWeight: pathname === "/groups" ? "bold" : "normal", cursor: "pointer" }} onClick={() => handleRouteChange("/groups")}>
                Groups
              </Typography>
              <Typography variant="subtitle2" style={{ fontWeight: pathname === "/charts" ? "bold" : "normal", cursor: "pointer" }} onClick={() => handleRouteChange("/charts")}>
                Charts
              </Typography>
              <Typography variant="subtitle2" style={{ fontWeight: pathname === "/evaluations" ? "bold" : "normal", cursor: "pointer" }} onClick={() => handleRouteChange("/evaluations")}>
                Evaluations
              </Typography>
            </Grid> */}

            <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {matches ? (
                <IconButton style={{ margin: "0px 5px" }} onClick={() => setOpen(true)}>
                  <MenuIcon />
                </IconButton>
              ) : null}
              <IconButton>
                <NotificationIcon />
              </IconButton>
              <Grid>
                <IconButton onClick={(event) => setAnchorEl(event && event.target && event.currentTarget)}>
                  <Avatar title={props.listUser?.FirstName + " " + props.listUser?.LastName} src={props.listUser?.Picture} />
                </IconButton>
                {anchorEl ? (
                  <SimpleListMenu anchorEl={anchorEl} options={[{ id: "logout", title: "Logout" }]} handleMenuItemClick={(options) => handleMenuItemClick(options)} onClose={() => setAnchorEl(null)} />
                ) : null}
              </Grid>
              {/* {matches ? (
                <IconButton style={{ margin: "0px 5px" }} onClick={() => setSideDrawerOpen(!sideDrawerOpen)}>
                  {!sideDrawerOpen ? <MenuIcon /> : <CloseIcon />}
                </IconButton>
              ) : null} */}
              {/* {sideDrawerOpen &&
                ReactDOM.createPortal(
                  <div style={modalWrapper}>
                    <List classes={{ root: classes.listRoot }}>
                      <ListItem
                        style={{ width: "70%" }}
                        classes={{ selected: classes.selected, button: classes.buttonHover }}
                        selected={pathname === "/members" ? true : false}
                        button
                        onClick={() => handleRouteChange("/members")}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h5" style={{ justifyContent: "center" }}>
                              Members
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem
                        style={{ width: "70%" }}
                        classes={{ selected: classes.selected, button: classes.buttonHover }}
                        selected={pathname === "/groups" ? true : false}
                        button
                        onClick={() => handleRouteChange("/groups")}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h5" style={{ justifyContent: "center" }}>
                              Groups
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem
                        style={{ width: "70%" }}
                        classes={{ selected: classes.selected, button: classes.buttonHover }}
                        selected={pathname === "/charts" ? true : false}
                        button
                        onClick={() => handleRouteChange("/charts")}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h5" style={{ justifyContent: "center" }}>
                              Charts
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem
                        style={{ width: "70%" }}
                        classes={{ selected: classes.selected, button: classes.buttonHover }}
                        selected={pathname === "/evaluations" ? true : false}
                        button
                        onClick={() => handleRouteChange("/evaluations")}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h5" style={{ justifyContent: "center" }}>
                              Evaluations
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </div>,
                  document.querySelector("#root")
                )} */}
            </Grid>
            {/* <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <Avatar alt="Remy Sharp" src={profile && profile.photoUrl} className={classes.large} />
            </IconButton> */}
          </Grid>
        </Toolbar>
      </AppBar>
      {logoutModalOpen ? (
        <DialogNotification
          // hideDividers
          dialogActions={[
            { text: "No", onClick: () => handleDialogWindowClose() },
            { text: "Yes", onClick: () => onDialogActionsOnClick(), proceedAction: true },
          ]}
          subRendercomponent={onRenderComponent()}
          title="Logout?"
          dialogRootProps={{ open: logoutModalOpen, onClose: () => setLogoutModalOpen(false) }}
        />
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    route: state.global.route,
    listUser: state.AuthReducer.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoute: (data) => dispatch(setRoute(data)),
    logout: () => dispatch(logout()),
    getUser: (data) => dispatch(getUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
