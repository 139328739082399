export const SIDEBAR_MENU = [
  { title: "Members", path: "/members" },
  { title: "Groups", path: "/groups", disabled: true },
  { title: "Charts", path: "/charts" },
  { title: "Evaluations", path: "/evaluations"},
  { title: "CCC Evaluations", path: "/cccevaluationlist"},
  // {title:"My Program" ,path:"/myprogram"},
  // {title:"Directory" ,path:"/directory"},
  // {title:"Home" ,path:"/home"},
 
];

export const RESIDENT_MENU = [
  { title: "Evaluations", path: "/evaluations" },
];

export const FACULTY_MENU = [
  { title: "Evaluations", path: "/evaluations" },
];

export const MANAGER_MENU = [
  { title: "Members", path: "/members" },
  { title: "Groups", path: "/groups", disabled: true },
  { title: "Charts", path: "/charts" },
  { title: "Evaluations", path: "/evaluations", disabled: true },
  { title: "CCC Evaluations", path: "/cccevaluationlist"},
  {title:"My Program" ,path:"/myprogram"},
  {title:"Directory " ,path:"/directory"},
  {title:"Home" ,path:"/home"},
 
];
