import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Typography } from "@material-ui/core";
export default function SimpleListMenu(props) {
  const { anchorEl, onClose, handleMenuItemClick, options, onCloseMenu, classes } = props;

  return (
    // anchorEl is the position where menu opens
    <div>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        className={classes}
        onClose={() => {
          onClose();
          onCloseMenu && onCloseMenu();
        }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {options &&
          options.length &&
          options.map((option, index) => (
            <MenuItem
              key={option.id}
              onClick={(e) => {
                ///onClick functions triggers the handleMenuItemClick props  from the main component
                handleMenuItemClick(option.id ? option.id : option.title, e);
                //onClose is to close the menu after the menu item is clicked
                onClose();
              }}
            >
              {/* option.title renders the menu item */}
              <Typography variant="body2" color="textSecondary">
                {option.title}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

SimpleListMenu.defaultProps = {
  anchorEl: null,
  onClose: () => {},
  handleMenuItemClick: () => {},
  options: [],
};
